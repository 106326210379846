import { getContent, getMatrix } from '@vue/services/api/utility.api'
import { AvailabilityMatrix } from '@vue/common/availabilityMatrix'

import {
  SET_CALCULATOR_CONTENT,
  SET_RESULT,
  SET_STORE_SELECT,
  SET_STORE_MODAL,
} from '@vue/common/constants/mutation.types'

import BigNumber from 'bignumber.js'

import { actionTypes } from '@vue/common/constants/store.types'
import { BUY_ID } from '@vue/common/constants/config'
import { nextMultiple } from '@vue/services/currency.service'

const state = {
  content: null,
  showResult: false,
  showStore: false,
  showStoreModal: false,
  matrix: null,
  type: BUY_ID,
}

const actions = {
  async getCalculatorContent({ commit }) {
    const { data } = await getContent()
    commit(SET_CALCULATOR_CONTENT, data)
  },
  getMatrix({ commit }) {
    getMatrix().then(({ data }) => {
      commit('setMatrix', new AvailabilityMatrix(data))
    })
  },
  [actionTypes.ToggleResult]({ commit, dispatch }, flag) {
    dispatch('currency/getCurrencyRate', null, { root: true })
    commit(SET_RESULT, flag)
  },
  toggleStoreSelect({ commit }, flag) {
    commit(SET_STORE_SELECT, flag)
  },
  [actionTypes.ToggleStoreModal]({ commit }, flag) {
    commit(SET_STORE_MODAL, flag)
  },
  [actionTypes.SetResult]({ commit }, flag) {
    commit(SET_RESULT, flag)
  }
}

const mutations = {
  [SET_CALCULATOR_CONTENT](state, content) {
    state.content = content
  },
  [SET_RESULT](state, flag) {
    state.showResult = flag
  },
  [SET_STORE_SELECT](state, flag) {
    state.showStore = flag
  },
  [SET_STORE_MODAL](state, flag) {
    state.showStoreModal = flag
  },
  setMatrix(state, availabilityMatrix) {
    state.matrix = availabilityMatrix
  },
  setCalculator(state, type) {
    state.type = type
  }
}

const getters = {
  isLoading: (state, getters, rootState) => {
    return state.content == null ||
      state.matrix == null ||
      rootState.store.defaultStore == null ||
      rootState.currency.selectedCurrency == null
  },
  nextTier: (state, getters, rootState) => {
    if(!rootState.currency.currencyRate.rateTiers || rootState.currency.currencyRate.rateTiers.length < 1) {
      return null
    }

    const qty = rootState.currency.targetQuantity || 0;

    const sortedRates = (rootState.currency.currencyRate.rateTiers)
      .filter((i) => i.amount > qty)
      .sort((a, b) => a.amount - b.amount)

    return sortedRates[0] || null
  },
  tieredRate: (state, getters, rootState) => {
    const blankTieredRate = {
      amount: null,
      price: null,
      rate: null
    };

    if(!rootState.currency.currencyRate.rateTiers || rootState.currency.currencyRate.rateTiers.length < 1) {
      return blankTieredRate
    }

    const qty = rootState.currency.targetQuantity || 0;

    const sortedRates = rootState.currency.currencyRate.rateTiers
      .filter(tier => tier.amount <= qty)
      .sort((a, b) => b.amount - a.amount)

    return sortedRates[0] || blankTieredRate
  },
  rate: (state, getters, rootState) => {
    if (rootState.currency.selectedCurrency === null || rootState.currency.currencyRate.rate === null)
      return 0

    return getters.tieredRate.rate
      ? getters.tieredRate.rate
      : rootState.currency.currencyRate.rate
  },
  // GBP -> FC
  rateForSourceQty: (state, getters, rootState) => (qty) => {
    const filteredThresholds = getters.rateTierThresholds
      .filter(threshold => qty >= threshold.threshold)

    const tiered = filteredThresholds[0] || null

    const fallback = rootState.currency.currencyRate;

    return  tiered?.rate || fallback?.rate || 0;
  },
  // FC -> GBP
  rateForTargetQty: (state, getters, rootState) => (rawQty, minDenom) => {
    const qty = nextMultiple(minDenom, Math.floor(rawQty))

    const filteredRates = (rootState.currency.currencyRate.rateTiers || [])
      .filter((i) => i.amount <= qty)
      .sort((a, b) => b.amount - a.amount)

    const tiered = filteredRates[0] || null

    const fallback = rootState.currency.currencyRate;

    return  tiered?.rate || fallback?.rate || 0;
  },

  rateTierThresholds: (state, getters, rootState) => {
    if(!rootState.currency.currencyRate.rateTiers || rootState.currency.currencyRate.rateTiers.length < 1)
      return []

    const thresholds = (rootState.currency.currencyRate.rateTiers)
      .map(x => {
        const threshold = BigNumber(x.amount).div(BigNumber(x.rate)).dp(2, BigNumber.ROUND_HALF_EVEN).toNumber()
        return {
          rate: x.rate,
          threshold
        }
      });   

    return thresholds.sort((a, b) => b.threshold - a.threshold)
  },
  price: (state, getters, rootState) => {
    if (rootState.currency.currencyRate.rate === null)
      return 0

    return getters.tieredRate.price
      ? getters.tieredRate.price
      : rootState.currency.currencyRate.price
  },
}

const caluclator = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default caluclator
