<template>
  <div v-if="selectedCurrency">
    <div
      v-if="showResult"
      class="payment"
      :class="{ 'payment-bordered': type === BUY_ID }"
    >
      <div v-if="isIframe" class="iframe">
        <a :href="cartUrl" target="_blank" class="button button--checkout">
          Add to cart
        </a>
      </div>
      <div v-else>
        <button
          class="button button--primary"
          @click="addToCart"
          v-if="!loading && !shoppingCart"
          id="addToCart"
          :disabled="!showAddToCartButton"
        >
          <span v-if="showAddToCartButton && type === BUY_ID">Add to cart</span>
          <span v-else-if="type === SELL_ID">Sell back now</span>
          <span v-else>Select a branch</span>
        </button>

        <button
          class="button button--checkout"
          v-if="!loading && shoppingCart"
          @click="redirectToCheckout"
        >
          Review &amp; checkout
        </button>
      </div>

      <div class="spinner" v-if="loading">
        <Spinner name="circle" color="var(--color-links)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { BUY_ID, SELL_ID, SITE_ID } from '@vue/common/constants/config'
import { createCart } from '@vue/services/api/cart.api'
import { saveToken } from '@vue/services/jwt.service'

import Spinner from 'vue-spinkit'

export default {
  name: 'CalculatorResult',
  components: {
    Spinner,
  },
  data() {
    return {
      BUY_ID,
      SELL_ID,
      loading: false,
      domain: this.$root.origin,
      isIframe: this.$root.isIframe,
    }
  },
  computed: {
    ...mapState({
      showResult: (state) => state.calculator.showResult,
      selectedProduct: (state) => state.product.selectedProduct,
      store: (state) => state.store.store,
      shoppingCart: (state) => state.cart.shoppingCart,
      selectedCurrency: (state) => state.currency.selectedCurrency,
      currencyRate: (state) => state.currency.currencyRate,
      quantity: (state) => state.currency.targetQuantity,
      type: (state) => state.calculator.type,
      content: (state) => state.calculator.content,
      target: (state) => state.currency.target,
    }),
    showAddToCartButton() {
      if (!this.store) return false

      // click and collect and not meta store
      if (this.selectedProduct.id === 2 && !this.store.isMeta) return true

      // home delivery and meta store
      if (this.selectedProduct.id === 1 && this.store.isMeta) return true

      if (this.selectedProduct.id === 3 && !this.store.isMeta) return true

      return false
    },
    iframeQuery() {
      const query = {
        currency: this.selectedCurrency.code,
        amount: this.target,
        product_id: this.selectedProduct.id
      }

      if (this.store.id !== 1) {
        query.store_id = this.store.id
      }

      return new URLSearchParams(query).toString()
    },
    cartUrl() {
      return this.selectedProduct.id === 3
        ? `${this.domain}/selling/view-cart?${this.iframeQuery}`
        : `${this.domain}/checkout/view-cart?${this.iframeQuery}`
    },
  },
  methods: {
    ...mapMutations({
      loadingCart: 'cart/loadingCart',
    }),
    ...mapActions({
      addLine: 'cart/addLine',
    }),
    addToCart() {
      const affiliateCode = sessionStorage.getItem('affiliateCode')
        ? sessionStorage.getItem('affiliateCode')
        : null

      this.loading = true

      if (!this.shoppingCart) {
        this.loadingCart(true)

        createCart({
          siteId: SITE_ID,
          storeId: this.store.id,
          productId: this.selectedProduct.id,
          affiliateCode: affiliateCode,
        }).then(({ data }) => {
          saveToken(data.token)
          this.loadingCart(false)
          this.addLine({
            currencyCode: this.selectedCurrency.code,
            qty: this.quantity,
          }).then(() => {
            this.redirectToCheckout();
          }).catch((err) => {
            this.loading = false;
          });
        })
      } else {
        this.addLine({
          currencyCode: this.selectedCurrency.code,
          qty: this.quantity,
        }).then(() => {
          this.redirectToCheckout();
        }).catch((err) => {
          this.loading = false;
        });
      }
    },
    redirectToCheckout() {
      window.location.href =
        this.shoppingCart.product.id === 3
          ? '/selling/view-cart'
          : '/checkout/view-cart'
    },
  },
}
</script>
