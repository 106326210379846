<template>
  <div
    class="amount-select"
    v-if="selectedCurrency"
    :class="{ 'visibility-hidden': !currencyRate }"
  >
    <div class="label">
      <div class="row">
        <span>{{
          content.amountSelectLabel || '2. Select how much you would like'
        }}</span>
        <Tooltip :content="content.amountTooltip" />
      </div>
      <div class="row"></div>
    </div>
    <div class="inputs">
      <div class="input" tabindex="-1">
        <small class="currency-code">£</small>
        <input
          maxlength="4"
          min="0"
          type="number"
          @keyup="onUpdateSource"
          :value="source"
          tabindex="0"
        />
      </div>
      <div class="separator">
        <span>=</span>
      </div>
      <div class="input" tabindex="-1">
        <small class="currency-code" v-html="selectedCurrency.symbol"></small>
        <input
          type="number"
          min="0"
          @keyup="onUpdateTarget"
          :value="target"
          tabindex="0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { nextMultiple } from '@vue/services/currency.service'
  import { DEFAULT_GBP, GBP_MAX_VALUE } from '@vue/common/constants/config'
import { showError } from '@vue/services/toast.service'
import BigNumber from 'bignumber.js'
import Tooltip from '@vue/common/components/Tooltip'


export default {
  name: 'AmountSelectBuy',
  components: {
    Tooltip,
  },
  data() {
    return {
      debounceTimer: null,
      lastHashCode: null,
      isInitial: true,
      DEFAULT_GBP,
      GBP_MAX_VALUE
    }
  },
  computed: {
    ...mapGetters({
      rate: 'calculator/rate',
      rateForSourceQty: 'calculator/rateForSourceQty',
      rateForTargetQty: 'calculator/rateForTargetQty',
      nextTier: 'calculator/nextTier',
    }),
    ...mapState({
      content: (state) => state.calculator.content,
      currencyRate: (state) => state.currency.currencyRate,
      selectedCurrency: (state) => state.currency.selectedCurrency,
      type: (state) => state.calculator.type,
      source: (s) => s.currency.source,
      target: (s) => s.currency.target,
    }),
  },
  watch: {
    'currencyRate': {
      handler(value) {
        if(value.rate == null)  {
          return
        }

        if(this.lastHashCode == value.hashCode) {
          return; // ignore irrelevant changes to rate.
        }

        this.lastHashCode = value.hashCode;

        if(this.isInitial) {
          this.isInitial = false;
          var params = new URLSearchParams(window.location.search);
          let amount = params.get('amount')
          const native = params.get('native') && params.get('native').toLowerCase() === "true"

          if(amount && native) {
            this.updateTarget(Math.floor(Number(amount) || 0));
            return;
          }
          if(amount) {
            this.updateSource(Math.floor(Number(amount) || 0));
            return;
          }
          this.updateSource(DEFAULT_GBP);
        } else {
          this.updateSource(this.source);
        }
      },
    },
    'source': {
      handler(value) {
        if (value && value > GBP_MAX_VALUE) {
          showError('Maximum order value is £' + GBP_MAX_VALUE.toLocaleString('en-US')) // shouldn't be hardcoded...
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setTargetQuantity: 'currency/setTargetQuantity',
      setSource: 'currency/setSource',
      setTarget: 'currency/setTarget'
    }),
    onUpdateSource(event){
      if(event.key == 'Tab') {
        return;
      }
      this.resetTimer();

      this.debounceTimer = setTimeout(() => {
        this.resetTimer();

        const rawQty = event.target.value;
        const qty = Number(rawQty) || 0;

        this.updateSource(qty);
      }, 500)
    },
    onUpdateTarget(event){
      if(event.key == 'Tab') {
        return;
      }
      this.resetTimer();

      this.debounceTimer = setTimeout(() => {
        this.resetTimer();

        const rawQty = event.target.value;
        const qty = Number(rawQty) || 0;

        this.updateTarget(qty);
      }, 500)
    },
    canUpdate() {
      // This component shouldn't ever receive rates for other products, but right now it does so handle it until refactor.
      return this.currencyRate.rate && this.selectedProduct?.id != 3;
    },
    // FC -> GBP
    updateTarget(qty) {
      if(!this.canUpdate()){
        this.reset();
        return;
      }

      const target = BigNumber(qty) // FC
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      const source = BigNumber(qty)
          .div(this.rateForTargetQty(qty,  this.selectedCurrency.minimumDenomination))
          .dp(2, BigNumber.ROUND_HALF_EVEN)
          .toNumber()

      this.setSource(source);
      this.setTarget(target);
      this.updateTargetQuantity(this.target)
    },
    // GBP -> FC
    updateSource(qty) {
      if(!this.canUpdate()){
        this.reset();
        return;
      }
      const source = BigNumber(qty) // GBP
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      const target = BigNumber(qty) // FC
        .times(this.rateForSourceQty(qty))
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      this.setSource(source);
      this.setTarget(target);
      this.updateTargetQuantity(this.target)
    },
    updateTargetQuantity(qty) {
      qty = Math.floor(qty) // lose decimals
      qty = nextMultiple(this.selectedCurrency.minimumDenomination, qty)
      this.setTargetQuantity(qty)
    },
    reset() {
      this.setSource(0);
      this.setTarget(0);
      this.setTargetQuantity(0);
    },
    resetTimer(){
      clearTimeout(this.debounceTimer);
      this.debounceTimer = null;
    }
  },
}
</script>
