<template>
  <div class="amount-result" v-if="selectedCurrency">
    <div class="flex">
      <div class="item">
        <span>You'll pay {{ total | price }}</span>
      </div>
      <div class="item">
        <span> You'll get {{ qty | price(selectedCurrency.symbol) }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js'
import { mapGetters, mapState } from 'vuex'
import { nextMultiple } from '@vue/services/currency.service'
export default {
  name: 'AmountResult',
  computed: {
    ...mapState('currency', [
      'targetQuantity',
      'selectedCurrency'
    ]),
    ...mapGetters({
      calculatorPrice: 'calculator/price',
    }),
    qty() {
      if (!this.selectedCurrency || !this.targetQuantity) {
        return 0
      }
      // account for bank note values, e.g. we don't sell 3 EUR
      return nextMultiple(
        this.selectedCurrency.minimumDenomination,
        this.targetQuantity
      )
    },
    total() {
      return BigNumber(this.qty)
        .times(BigNumber(this.calculatorPrice))
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();
    }
  }
}
</script>
